import * as React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo";
import { Link } from "gatsby";
import * as styles from './404.module.scss';
import { HoldingPageDivider } from '../components/holding/HoldingPage';

const FourOhFour = () => {
  return (
    <Layout>
      <Seo title='404' />
      <div className={styles.body}>
        <h1 className={styles.title}>Oh no!</h1>
        <p className={styles.subtitle}>We can't seem to find the page you're looking for.<br /><Link to={'/'}>Back to the homepage</Link></p>
      </div>
    </Layout >
  )
}

export default FourOhFour;